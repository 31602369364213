<template>
  <v-dialog v-model="dialog" width="886" @click:outside='closeDialog' persistent>
      <div class="dialog_wrapper">
          <p class="dialog_wrapper__title">Спасибо! Бронь прошла успешно!</p>
          <p class="dialog_wrapper__subtitle">Хотите забронировать еще услугу?</p>
          <div class="boxes">
            <router-link
                v-for="item in options"
                :key="item.title"
                tag="div"
                :to="item.to"
            >
                <img :src="item.img" alt="">
                <p>{{item.title}}</p>
            </router-link>
          </div>
          <div class="button_wrapper">
            <v-btn outlined width="245" height="48" color="primary" @click="closeDialog">Завершить</v-btn>
          </div>
      </div>
  </v-dialog>
</template>

<script>
export default {
    props: {
        dialog: Boolean,
        exept: String
    },
    data: () => ({
        boxes: [
            {title: 'Проживание', img: require('@/assets/images/administration/residence.svg'), to: '/booking/residence'},
            {title: 'Ж/Д', img: require('@/assets/images/administration/trains.svg'), to: '/booking/trains'},
            {title: 'Автобус', img: require('@/assets/images/administration/buses.svg'), to: '/booking/buses'},
            {title: 'Экскурсии', img: require('@/assets/images/administration/excursions.svg'), to: '/booking/tours'},
            {title: 'Трансфер', img: require('@/assets/images/administration/transfer.svg'), to: '/booking/transfer'},
        ]
    }),
    computed: {
        options() {
            return this.boxes.filter(box => box.title!==this.exept)
        }
    },
    methods: {
        closeDialog() {
            this.$emit('closeDialog')
        }
    }
}
</script>

<style lang="scss" scoped>
.dialog_wrapper {
    padding: 40px 30px;
    background: #fff;
    &__title {
        text-align: center;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #FF8413;
        margin-bottom: 40px;
    }
    &__subtitle {
        text-align: center;
        font-size: 16px;
        line-height: 19px;
        color: #021011;
        margin-bottom: 24px;
    }
    .boxes {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        justify-content: center;
        align-items: center;
        div {
            max-width: 193px;
            height: 120px;
            background: #FFFFFF;
            border: 1px solid #F5F5F5;
            box-shadow: 0px 10px 20px rgba(138, 149, 158, 0.1);
            border-radius: 5px;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            p {
                font-family: 'Gilroy', sans-serif;
                margin-top: 16px;
                font-size: 16px;
                line-height: 19px;
                margin-bottom: 25px;
            }
        }
    }
    .button_wrapper {
        padding-top: 25px;
        text-align: center;
    }
}

</style>