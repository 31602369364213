<template>
  <div>
    <p class="data_title">Контактная информация</p>
    <v-form
      ref="form"
      lazy-validation
      v-for="(i, idx) in model.length"
      :key="i"
    >
      <v-row v-if="idx > 0" align="center" class="px-3">
        <p class="mt-5">Данные о дополнительном человеке</p>
        <v-spacer />
        <v-btn @click="removePerson(idx)" icon color="error">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-row>
      <input-content :items="inputItems" :model="model[idx]" />
    </v-form>
    <v-btn
      v-if="limit > model.length"
      color="primary"
      outlined
      class="mt-4"
      @click="addPerson"
    >
      Добавить человека
    </v-btn>
    <v-row class="px-3 mt-5" align="center">
      <v-form ref="channel_form" lazy-validation>
        <v-autocomplete
          v-if="role != 'agent'"
          v-model="seal_channel"
          :items="channelsList"
          label="Канал продажи"
          item-text="name"
          item-value="id"
          height="44"
          hide-details
          outlined
          dense
          class="mr-3"
          @input="getSales"
        >
        </v-autocomplete>
      </v-form>
      <v-btn color="primary" width="160" height="44" @click="submitHandler">
        Забронировать
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import InputContent from "@/components/inputs/inputContent.vue";
export default {
  props: {
    limit: Number,
  },
  components: {
    InputContent,
  },
  data: () => ({
    channelsList: [],
    seal_channel: "",
    model: [{}],
    inputItems: [
      {
        type: "text",
        label: "Имя",
        rules: "required",
        model: "first_name",
        cols: 6,
      },
      {
        type: "text",
        label: "Фамилия",
        rules: "required",
        model: "last_name",
        cols: 6,
      },
      // {
      //   type: "text",
      //   label: "Отчество",
      //   rules: "required",
      //   model: "middle_name",
      //   cols: 6,
      // },
      {
        type: "text",
        label: "Ваш номер телефона",
        rules: "required",
        mask: "+7 (###) ### ##-##",
        model: "phone_number",
        cols: 6,
      },
      {
        type: "text",
        label: "Ваш e-mail",
        rules: "email",
        model: "email",
        cols: 6,
      },
      // {
      //   type: "text",
      //   label: "Дата рождения",
      //   placeholder: "ДД.ММ.ГГГГ",
      //   mask: "##.##.####",
      //   rules: "required",
      //   model: "birth_date",
      //   cols: 6,
      // },
      {
        type: "text",
        label: "Номер документа",
        placeholder: "Номер документа",
        rules: "required",
        model: "document_number",
        cols: 6,
      },
      {
        type: "text",
        label: "Комментарий",
        placeholder: "Комментарий",
        rules: "none",
        model: "comment",
        cols: 6,
      },
    ],
    role: null,
  }),
  created() {
    this.getSales()
  },
  methods: {
    async getSales(){
      this.role = this.$store.getters["auth/getUser"].role;
      try {
        if(this.role == 'operator') {
          await this.$store.dispatch(`lists/getSealChannelsForOperators`);
          this.channelsList = this.$store.getters[`lists/getSealChannels`];
        }
        else{
          await this.$store.dispatch(`lists/getSealChannels`);
          this.channelsList = this.$store.getters[`lists/getSealChannels`];
        }
      } catch (e) {
        console.error(e);
      }
    },
    removePerson(idx) {
      this.model.splice(idx, 1);
    },
    addPerson() {
      if (this.limit > this.model.length) {
        this.model.push({});
      }
    },
    submitHandler() {
      let is_valid = false;
      this.$refs.form.forEach((form) => {
        if (form.validate()) {
          is_valid = true;
        }
      });
      if (!this.$refs.channel_form.validate()) return;
      if (!is_valid) return;
      this.$emit("submitHandler", {
        user: this.model,
        seal_channel: this.seal_channel,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.data_title {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #021011;
  margin-bottom: 32px;
}
</style>
