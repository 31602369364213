<template>
  <div class="counter d-flex justify-center align-center">
    <slot name="dec" :decHandler="dec" :disabled="value === min">
      <v-btn @click="dec" :disabled="value === min" fab outlined color="primary"><v-icon small>mdi-minus</v-icon></v-btn>
    </slot>
    <span class="mx-3">{{ value }}</span>
    <slot name="inc" :incHandler="inc" :disabled="value === max">
      <v-btn @click="inc" :disabled="value === max" fab outlined color="primary"><v-icon small>mdi-plus</v-icon></v-btn>
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    value: Number,
    max: {
      type: Number,
      default: 100000,
    },
    min: {
      type: Number,
      default: 1,
    },
  },
  methods: {
    inc() {
      this.$emit("input", this.value + 1);
    },
    dec() {
      this.$emit("input", this.value - 1);
    },
  },
};
</script>

<style lang="scss">
.counter {
  .v-btn {
    width: 25px;
    height: 25px;
  }
}
</style>
