<template>
  <div>
    <vue-breadcrumbs :breadCrumbs="breadCrumbs" />
    <div class="vue_card">
      <booking-form @submitHandler="submitHandler" :limit="1" />
      <div class="vue_card__info">
        <div v-for="item in bookings" :key="item.id" class="single_room">
          <p class="single_room__title">Номер</p>
          <div class="single_room__header">
            <div
              class="avatar"
              :style="{ backgroundImage: `url(${item.main_image || img})` }"
            ></div>
            <div>
              <div>
                <p v-text="types[item.accommodation_type]"></p>
                <p v-text="item.hotel"></p>
                <p>
                  <img src="@/assets/images/ui/location_orange.svg" alt="" />{{
                    item.location
                  }}
                </p>
                <h4
                  style="font-weight: 500; margin-top: 10px"
                  v-text="item.category_name"
                ></h4>
                <v-btn
                  class="text-none py-5 w-100 mt-5"
                  outlined
                  color="primary"
                  block
                  @click="showServiceModel(item)"
                >
                  Дополнительная услуга +
                </v-btn>
              </div>
            </div>
          </div>
          <p class="people_count">
            <v-icon color="primary" class="mr-2">mdi-calendar</v-icon>
            {{ $moment(new Date(item.check_in * 1000)).format("LL") }} -
            {{ $moment(new Date(item.check_out * 1000)).format("LL") }}
          </p>
          <p class="people_count">
            <v-icon color="primary" class="mr-2">mdi-account</v-icon>
            Доп места:
            {{ adultCount(item.adult_count) }},
            {{ childCount(item.child_count) }}
          </p>
          <div
            class="services_block"
            v-if="item.calculated_by.services.length > 0"
          >
            <h4>Доп услуги:</h4>
            <div>
              <p
                class="service_block"
                v-for="subitem in item.calculated_by.services"
                :key="subitem.id"
              >
                {{ subitem.name }} -
                {{ subitem.price.toLocaleString("KZ-kz") }} KZT
              </p>
            </div>
          </div>
          <div class="room_time">
            <p>
              Время заезда:
              <span
                v-text="$moment(new Date(item.check_in * 1000)).format('HH:mm')"
              ></span>
            </p>
            <p>
              Время выезда:
              <span
                v-text="
                  $moment(new Date(item.check_out * 1000)).format('HH:mm')
                "
              ></span>
            </p>
          </div>
          <div class="price">
            <p>{{ item.calculated_by.days | convertDays }}</p>
            <p>
              {{
                (
                  item.calculated_by.total + item.calculated_by.advance_booking
                ).toLocaleString("KZ-kz")
              }}
              KZT
            </p>
          </div>
        </div>
        <h2 class="single_room__title">Скидки</h2>
        <div
          class="total_price total_discounts"
          v-for="item in discounts"
          :key="item.id"
        >
          <p v-text="item.discount_name"></p>
          <p
            v-text="
              (
                bookings[0].calculated_by.days *
                item.quantity *
                item.value
              ).toLocaleString('KZ-kz') + ' KZT'
            "
          ></p>
        </div>
        <div class="total_price">
          <p>Итого</p>
          <p>
            {{ totalPrice.toLocaleString("KZ-kz") }}
            KZT
          </p>
        </div>
      </div>
    </div>
    <vue-booking-modal
      :dialog="dialog"
      :exept="'Экскурсии'"
      @closeDialog="closeDialog"
    />
    <AdditionalServices
      class="mb-6"
      :visible="showModal"
      :services="services"
      :selectedOrder="selectedOrder"
      @getDetails="getDetails"
      @close="closeModal"
    ></AdditionalServices>
  </div>
</template>

<script>
import VueBreadcrumbs from "@/components/ui/vueBreadcrumbs.vue";
import BookingForm from "@/components/booking/bookingForm.vue";
import VueBookingModal from "@/components/ui/vueBookingModal.vue";
import { accommodationService } from "@/services/accommodation.service.js";
import AdditionalServices from "@/components/booking/residence/residenceAdditional";

export default {
  components: {
    VueBreadcrumbs,
    BookingForm,
    VueBookingModal,
    AdditionalServices,
  },
  data: () => ({
    img: require("@/assets/images/test/test.jpg"),
    dialog: false,
    bookings: [],
    types: {
      rest_zone: "Зона отдыха",
      hotel: "Отель",
      guest_house: "Гостевой дом",
    },
    showModal: false,
    selectedOrder: {},
    services: [],
    discounts: [],
    totalPrice: 0,
  }),
  computed: {
    breadCrumbs() {
      let query = { ...this.$route.query };
      delete query.id;
      return [
        {
          text: "Бронирование проживания",
          to: "/booking/residence/",
        },
        {
          text: "Зона отдыха",
          to: `/booking/residence/${this.$route.params.id}/`,
          query: query,
        },
        {
          text: "Бронирование",
          to: `/booking/residence/${this.$route.params.id}/booking`,
          query: this.$route.query,
          disabled: true,
        },
      ];
    },
  },
  filters: {
    convertDays(value) {
      if (value == 0) return `0 ночей`;
      else if (value == 1) return `1 ночь`;
      else if (value > 1 && value < 5) return `${value} ночи`;
      else return `${value} ночей`;
    },
  },
  created() {
    this.getDetails();
    this.getServices();
  },
  methods: {
    closeModal() {
      this.showModal = false;
      this.selectedOrder = null;
      this.services.map((el) => {
        el.quantity = 0;
      });
    },
    showServiceModel(item) {
      this.showModal = true;
      this.selectedOrder = item;
    },
    adultCount(adult_count) {
      if (adult_count == 1) {
        return `${adult_count} взрослый`;
      } else {
        return `${adult_count} взрослых`;
      }
    },
    childCount(child_count) {
      if (child_count == 1) {
        return `${child_count} ребенок`;
      } else if (child_count == 0) {
        return `${child_count} детей`;
      } else if (child_count > 1 && child_count < 5) {
        return `${child_count} ребенков`;
      } else {
        return `${child_count} детей`;
      }
    },
    async getDetails() {
      let bookings = "";
      if (this.$route.query.id && typeof this.$route.query.id == "object")
        bookings = this.$route.query.id.join(",");
      else if (this.$route.query.id) bookings = this.$route.query.id;
      let res = await accommodationService.getReserves({ bookings: bookings });
      console.log(res);
      this.bookings = res.orders;
      this.discounts = res.discounts;

      let days = this.bookings[0].calculated_by.days;
      let price = 0;
      this.discounts.forEach((e) => {
        price += days * e.value * e.quantity;
      });
      let total_price = this.bookings.reduce((prev, cur) => {
        return (
          prev + cur.calculated_by.total + cur.calculated_by.advance_booking
        );
      }, 0);
      this.totalPrice = total_price - price;
    },
    closeDialog() {
      this.dialog = false;
      this.$router.push("/booking/residence");
    },
    async submitHandler(value) {
      console.log(value);
      let body = {
        seal_channel: value.seal_channel,
        bookings: this.$route.query.id,
        client: {
          first_name: value.user[0].first_name,
          last_name: value.user[0].last_name,
          // middle_name: value.user[0].middle_name,
          phone_number: value.user[0].phone_number,
          email: value.user[0].email,
          document_number: value.user[0].document_number,
        },
        comment: value.user[0].comment,
      };
      let res = await accommodationService.addClient(body);
      this.dialog = true;
    },
    async getServices() {
      let res = await accommodationService.getServices(this.$route.params.id);
      res.forEach((e) => {
        e.quantity = 0;
      });
      this.services = res;
    },
  },
};
</script>

<style lang="scss" scoped>
.services_block {
  display: flex;
  margin-top: 20px;
  h4 {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #ff8413;
    margin-right: 5px;
  }
  p {
    font-family: "Gilroy", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #021011;
  }
}
.vue_card {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: start;
  grid-column-gap: 20px;
  &__info {
    background: #ffffff;
    box-shadow: 0px 10px 20px rgba(146, 178, 193, 0.3);
    border-radius: 5px;
    padding: 0px 20px;
  }
}
.single_room {
  border-bottom: 1px solid #ff8413;
  p {
    margin-bottom: 0;
  }
  &__title {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #021011;
    margin-top: 20px;
  }
  &__header {
    display: grid;
    grid-template-columns: 40% 1fr;
    grid-column-gap: 20px;
    align-items: start;
    margin-top: 20px;
    margin-bottom: 40px;
    .avatar {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 181px;
      border-radius: 5px;
    }
    div {
      &:last-of-type {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        height: 100%;
        div {
          display: unset;
        }
        p {
          &:nth-of-type(1) {
            font-size: 12px;
            line-height: 14px;
            color: rgba(2, 16, 17, 0.5);
            margin-top: 10px;
          }
          &:nth-of-type(2) {
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: #000000;
            margin: 6px 0;
          }
          &:nth-of-type(3) {
            font-size: 12px;
            line-height: 14px;
            color: #000000;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            img {
              margin-right: 6px;
            }
          }
          &:nth-of-type(4) {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #000000;
            margin-top: 12px;
          }
        }
      }
    }
  }
  .people_count {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 16px;
    line-height: 19px;
    color: #021011;
    &:last-of-type {
      margin-top: 12px;
    }
  }
  .room_time {
    border-top: 1px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9;
    padding: 20px 0;
    margin: 20px 0;
    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #ff8413;
      span {
        color: #021011;
      }
      &:last-of-type {
        margin-top: 12px;
      }
    }
  }
  .price {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    p {
      font-size: 16px;
      line-height: 19px;
      color: #001239;
      &:last-of-type {
        font-weight: 500;
      }
    }
  }
}
.total_price {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  p {
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #ff8413;
    &:last-of-type {
      font-weight: 500;
    }
  }
}
.total_discounts {
  margin-top: 10px;
  p {
    color: #001239;
    text-transform: initial;
  }
}
</style>
