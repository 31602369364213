<template>
  <v-card class="additional-services">
    <v-row justify="center">
      <v-dialog v-model="show" max-width="767">
        <v-card class="pa-8">
          <h2 class="title mb-4">Дополнительные услуги</h2>
          <div
            class="item d-flex align-center justify-space-between"
            v-for="(item, index) in services"
            :key="index"
          >
            <p class="mb-0 name">{{ item.name }}</p>
            <div class="d-flex align-center">
              <div class="mr-4">
                <p class="mb-0 price">{{ item.price }} KZT</p>
                <p class="mb-0 guest">
                  {{ getServiceType(item.service_type) }}
                </p>
              </div>
              <Counter v-model="item.quantity" :min="0" class="mr-4" />
            </div>
          </div>
          <v-btn class="text-none py-4" color="primary" @click="sendServices"
            >Сохранить</v-btn
          >
        </v-card>
      </v-dialog>
    </v-row>
    <v-snackbar top v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
    </v-snackbar>
  </v-card>
</template>

<script>
import Counter from "@/components/ui/Counter";
import { accommodationService } from "@/services/accommodation.service.js";

export default {
  name: "AdditionalServices",
  data() {
    return {
      bookCount: 1,
      snack: false,
      snackColor: "success",
      snackText: "",
    };
  },
  components: {
    Counter,
  },
  props: {
    services: { type: Array },
    visible: {
      type: Boolean,
    },
    selectedOrder: {
      type: Object,
    },
  },
  watch: {
    selectedOrder() {
      this.services.map((el) => {
        if (this.selectedOrder) {
          if (this.selectedOrder.services)
            this.selectedOrder.services.map((el2) => {
              if (el2.id == el.id) {
                el.quantity = el2.quantity;
              }
            });
        }
      });
    },
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  methods: {
    getServiceType(service_type) {
      switch (service_type) {
        case "day":
          return "за сутки";
        default:
          return "единоразово";
      }
    },
    async sendServices() {
      console.log(this.selectedOrder);
      let body = {
        booking: this.selectedOrder.booking_id,
        service_list: [],
      };
      this.services.forEach((e) => {
        if (e.quantity > 0)
          body.service_list.push({
            quantity: e.quantity,
            service: e.id,
          });
      });
      await accommodationService.postServices(body);
      this.snack = true;
      this.snackColor = "success";
      this.snackText = "Услуги добавлены!";
      this.$emit("getDetails");
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  border-bottom: 1px solid #e9e9e9;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}
.title {
  color: #52b1ba;
}
.name {
  font-size: 20px;
}
.price {
  color: #ff8413;
  font-weight: 600;
  font-size: 20px;
  text-align: right;
}
.guest {
  font-weight: 600;
  font-size: 16px;
  color: #bab8b8;
  text-align: right;
}
.close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  background: transparent !important;
  box-shadow: initial;
  min-width: initial !important;
  height: initial !important;
}
</style>
